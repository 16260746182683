import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators'
import 'rxjs/add/operator/map';
import { CommonService, DtoService, FunctService, UtilService } from "@services/index";
import { forkJoin } from 'rxjs';

declare var require: any;
@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  token: any;
  userProfileModel: any;
  isUserLoggedIn = false;
  version: any;
  deviceId: any;
  public topupCount = {
    notopup: 0,
    topup: 0
  }
  constructor(private translateService: TranslateService, private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService,
    private route: ActivatedRoute, private router: Router, private storage: LocalStorageService, private funct: FunctService, private _cservice: CommonService) {
    this.deviceId = this.storage.retrieve('localDeviceId');
  }

  public userAgent: any;
  ngOnInit(): void {
    // this.deviceId=this.storage.retrieve('localDeviceId');   
    if (this.storage.retrieve('agentModel')) {
      this.userAgent = JSON.parse(atob(this.storage.retrieve('agentModel')));
    }
    this.isUserLoggedIn = this.storage.retrieve('isUserLoggedIn');

    if (this.isUserLoggedIn == false) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 1000,
        positionClass: 'toast-top-center',
      });
      return;

    }
    this.getUserProfile();
    this.getTopupCount();
    this.version = require('@baseUrl/package.json').version;

  }
  
  loginAccount() {
    if (this.deviceId != null) {
      this.router.navigate(['/account/login', this.deviceId], { state: { parentLink: "/profile/me-page/mobile" }, replaceUrl: true });
      return;
    }
    this.router.navigate(['/account/login'], { state: { parentLink: "/profile/me-page" }, replaceUrl: true })
  }
  handleError(error: HttpErrorResponse) {
    this.spinner.hide("refreshLoading");

    if (error.status == 0) {
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      return;
    }

    if (error.status == 423 || error.status == 417) {

      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 1000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
      return;
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      return;
    }
    this.toastr.error("", error.statusText, {
      timeOut: 3000,
      positionClass: 'toast-top-center',
    });
    return;
    // return throwError(error);
  }
  getUserProfile() {
    this.spinner.show("refreshLoading");
    this.isUserLoggedIn = false;
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'user/UserProfile', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.isUserLoggedIn = true;
          this.spinner.hide("refreshLoading");          
          //console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response)); 
          this.userProfileModel = this.dto.Response;
          localStorage.setItem('my_gamereferral_code', this.userProfileModel.my_gamereferral_code);
          this.getPhoneNumber();
          this.isUserLoggedIn = this.storage.retrieve('isUserLoggedIn');
        });
    this.spinner.hide();
  }

  getTopupCount() {
    const prefix = this.storage.retrieve('localPhonePrefix');
    const phone = this.storage.retrieve('localPhoneValue');
    let phno = '';
    if (phone.startsWith('0')) {
      phno = prefix + phone.substring(1, phone.length);
    }
    else {
      phno = prefix + phone;
    }
    let noTopUp = this._cservice.getNoTopupUsersCount(phno);
    let topUp = this._cservice.getTopupUsersCount(phno);
    forkJoin([noTopUp, topUp]).subscribe((results) => {
      console.log(results)
      this.topupCount.notopup = results[0];
      this.topupCount.topup = results[1];
    })
  }
  getPhoneNumber() {
    if (this.userProfileModel.phone_no != null) {
      this.userProfileModel.phone_no = "*******" +
        this.userProfileModel.phone_no.substring(
          this.userProfileModel.phone_no.length - 4, this.userProfileModel.phone_no.length).toString();
    }
  }

  goToResult() {
    this.router.navigate(['/d-page/result'], { state: { type: 'all' } });
  }
  // bankAccount() {
  //   this.router.navigate(['/profile/withdraw-change-acc']);
  // }

  bankAccounts() {
    this.router.navigate(['/profile/withdraw-change-acc']);
  }

  goByRoute(url: string) {
    this.router.navigate([url]);

  }
  goToTopup() {
    this.router.navigate(['/agent/topup-users']);
  }

  goToNoTopup() {
    this.router.navigate(['/agent/no-topup-users']);
  }
  betHistory() {
    sessionStorage.setItem('type', 'all');
    sessionStorage.setItem('typeUrl', '2D');
    this.router.navigate(['/d-page/bet-history']);
  }
  goToMyUsers() {
    this.router.navigate(['/agent/my-users'], { state: { type: 'all', typeUrl: '2D' } });
  }
  goToAgentIncome() {
    this.router.navigate(['/agent/agent-income']);
  }
  goToMyGameUsers(code) {
    this.router.navigate(['/agent/game-users'], {queryParams: {code: code}});
  }
  goToSearchingUsers(code) {
    this.router.navigate(['/agent/searching-users'], { queryParams: {referral_code: code}});
  }
  refreshPage() {
    this.spinner.show("refreshLoading");
    this.ngOnInit();
    setTimeout(() => {
      this.spinner.hide("refreshLoading");
    }, 1000);
  }

  changeName() {
    // console.log(name.length)
    var name = this.userProfileModel.name;
    if (name != null && name.length > 30) {
      name = name.substring(0, 20) + " ...";
      return name;
    }
    else {
      return name;
    }
  }

  goToProfileDetail() {
    //  this.router.navigate(['/profile-detail','?openinnewtap=1'],{state: {parentLink: '/me-page'},replaceUrl: true})
    this.router.navigateByUrl('/profile/profile-detail?openinnewtap=1')
    // [routerLink]="['/profile-detail?openinnewtap=1']" replaceUrl="true" [state]="{parentLink: '/me-page'}"
  }
}
