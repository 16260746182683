<app-page-header (myEvent)="refreshPage()">{{ 'meTap' | translate }}</app-page-header>

<div class="main__wrapper" id="appCapsule">
    <div class="header">
        <div class="" *ngIf="!isUserLoggedIn">
            <a class="profile__content" (click)="loginAccount()" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <ion-icon name="person-circle-outline" style="font-size: 60px; color: #fff;"></ion-icon>
                <h3>{{ 'click_to_login' | translate}}</h3>
            </a>
        </div>
        <div class="wallet-account" *ngIf="isUserLoggedIn">
            <a [routerLink]="['/profile/profile-detail']" >
                <div class="account-bgcolor">
                    <div
                        *ngIf="userProfileModel == undefined || userProfileModel.imageUrl == null || userProfileModel.imageUrl == ''">
                        <ion-icon name="person-circle-outline" class="account-login-active py-2"></ion-icon>
                    </div>
                    <div *ngIf="userProfileModel.imageUrl != null" class="image__wrapper">
                        <img [src]="userProfileModel.imageUrl">
                    </div>
                    <h4 class="mt-2 account-title-active"
                        *ngIf="userProfileModel != undefined || userProfileModel.imageUrl != null">{{changeName()}}
                        <div>{{userProfileModel.phone_no}}</div>
                    </h4>
                </div>
            </a>
            <!--Waves Container-->
            <div>
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="parallax">
                        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
            <!--Waves end-->
        </div>
    </div>
    <!-- end header -->
    <div>
        <ul class="listview image-listview media flush pt-1">
            <li *ngIf="isUserLoggedIn">
                <app-nav-item (click)="betHistory()" iconUrl="assets/img/bethistory.png">
                    {{ 'betHistory' | translate}}
                </app-nav-item>
            </li>
            <li *ngIf="!isUserLoggedIn || isUserLoggedIn ">
                <app-nav-item (click)="goToResult()" iconUrl="assets/img/result.png">
                    {{ 'result' | translate}}
                </app-nav-item>
            </li>

            <li *ngIf="isUserLoggedIn">
                <ng-container *ngIf="userAgent?.isAgent==true && userAgent?.my_Referral_code !=null">
                    <app-nav-item iconUrl="assets/img/agent.png" (click)="goToMyUsers()">
                        {{ 'my_users' | translate}}
                    </app-nav-item>
                </ng-container>

            </li>
            <li *ngIf="isUserLoggedIn">
                <ng-container *ngIf="userAgent?.isAgent==true && userAgent?.my_Referral_code !=null">
                    <app-nav-item iconUrl="assets/img/agent_income.png" (click)="goToAgentIncome()">
                        {{ 'agent_income' | translate}}
                    </app-nav-item>
                </ng-container>

            </li>
            <li *ngIf="isUserLoggedIn && userProfileModel?.isGame_agent">
                <ng-container >
                    <app-nav-item iconUrl="assets/img/gamer.png" (click)="goToSearchingUsers(userProfileModel?.my_gamereferral_code)">
                        {{ 'search_user' | translate}}
                    </app-nav-item>
                </ng-container>
            </li>
            <li *ngIf="isUserLoggedIn">
                <ng-container *ngIf="userProfileModel?.isGame_agent 
                && userProfileModel?.my_gamereferral_code != null">
                    <app-nav-item iconUrl="assets/img/income.png" (click)="goToMyGameUsers(userProfileModel?.my_gamereferral_code)">
                        {{ 'my_game_users' | translate}}
                    </app-nav-item>
                </ng-container>

            </li>
            <li *ngIf="isUserLoggedIn">
                <ng-container *ngIf="userAgent?.isAgent==true && userAgent?.my_Referral_code !=null">
                    <app-nav-item iconUrl="assets/img/topup-users.png" (click)="goToTopup()">
                        Topup Users
                        <div class="badge" badge>
                            {{topupCount?.topup}}

                        </div>
                    </app-nav-item>

                </ng-container>

            </li>

            <li *ngIf="isUserLoggedIn">
                <ng-container *ngIf="userAgent?.isAgent==true && userAgent?.my_Referral_code !=null">
                    <app-nav-item iconUrl="assets/img/no-topup-users.png" (click)="goToNoTopup()">
                        No Topup Users
                        <div class="badge" badge>
                           {{topupCount?.notopup}}
                        </div>
                    </app-nav-item>
                </ng-container>

            </li>

            <li *ngIf="isUserLoggedIn">
                <app-nav-item iconUrl="assets/img/mylevel.png" (click)="goByRoute('/level')">
                    {{ 'my_level' | translate}}

                </app-nav-item>
            </li>

            <li *ngIf="isUserLoggedIn">
                <app-nav-item (click)="bankAccounts()" iconUrl="assets/img/bankacc.png">
                    {{ 'my_bank_accounts' | translate}}
                </app-nav-item>

            </li>

            <li *ngIf="isUserLoggedIn">
                <app-nav-item (click)="goByRoute('/profile/change-password')" iconUrl="assets/img/cpassword.png">
                    {{ 'changePwd' | translate}}
                </app-nav-item>

            </li>
            <li *ngIf="isUserLoggedIn">
                <app-nav-item (click)="goByRoute('/profile/invite-code')" iconUrl="assets/img/referralcode.png">
                    {{ 'enterRef' | translate}}
                    <span *ngIf="userProfileModel.referral_code != null">{{userProfileModel.referral_code}}</span>
                </app-nav-item>
            </li>
            <li *ngIf="isUserLoggedIn">
                <ng-container *ngIf="userAgent?.isAgent==true && userAgent?.my_Referral_code !=null">
                    <app-nav-item iconUrl="assets/img/mycode.png">
                        {{ 'myenterRef' | translate}}

                        <span *ngIf="userAgent.my_Referral_code != null">{{userAgent.my_Referral_code}}</span>

                    </app-nav-item>
                </ng-container>
            </li>
            <li *ngIf="isUserLoggedIn">
                <app-nav-item (click)="goByRoute('/profile/gamereferral-code')" iconUrl="assets/img/referralcode.png">
                    {{ 'enterGameRef' | translate}}
                    <span *ngIf="userProfileModel.gamereferral_code != null">{{userProfileModel.gamereferral_code}}</span>
                </app-nav-item>
            </li>
            <li *ngIf="isUserLoggedIn && userProfileModel?.isGame_agent">
                <app-nav-item  iconUrl="assets/img/mycode.png">
                    {{ 'enterMyGameRef' | translate}}
                    <span *ngIf="userProfileModel.my_gamereferral_code != null">{{userProfileModel.my_gamereferral_code}}</span>
                </app-nav-item>
            </li>

        

            <li *ngIf="isUserLoggedIn">
                <app-nav-item (click)="goByRoute('/profile/feedback')" iconUrl="assets/img/feedback.png">
                    {{ 'feedback' | translate}}

                </app-nav-item>

            </li>
            <li *ngIf="isUserLoggedIn">
                <app-nav-item iconUrl="assets/img/version.png">
                    {{ 'version' | translate}} {{ version }}

                </app-nav-item>
            </li>



            <li *ngIf="deviceId != null">
                <app-nav-item (click)="goByRoute('/profile/me-page/'+deviceId)" iconUrl="assets/img/version-update.png">
                    {{ 'cleanCache' | translate}}

                </app-nav-item>


            </li>

            <li *ngIf="isUserLoggedIn">
                <logout></logout>
            </li>
        </ul>
    </div>
</div>


<ngx-spinner name="refreshLoading" bdOpacity=0.9 size="medium" color="#00acc1" type="ball-spin-clockwise"
    [fullScreen]="true"></ngx-spinner>


<app-navigation-bar></app-navigation-bar>